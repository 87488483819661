import React, { useEffect, useState } from 'react'
import { css } from 'emotion'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import { get } from 'lodash-es'
import FileInput from 'react-files'
import { Button } from 'oio-react'
import uuidv4 from 'src/sites/kits/Utils/uuidv4'

const ConnectedFileInput = ({
   accepts = ['*/*'],
   multiple = false,
   name,
   required = false,
   formik
}) => {
   const fieldId = React.useRef(uuidv4())
   const [error, setError] = useState()
   const [files, setFiles] = useState(
      get(formik.values, name)
         ? get(formik.values, name)
         : []
   )

   useEffect(() => {
      // Force the underlying html component to reset so that we can properly enforce `required`
      if (files.length === 0) {
         const $fileInput = document.getElementById(`reg-form-${fieldId.current}`)
         $fileInput.value = ''
      }

      formik.setFieldValue(name, files)
   }, [name, files])

   return (
      <>
         {error && (
            <div>{`Error: ${error.message}`}</div>
         )}
         <FileInput
            clickable
            disabled
            accepts={accepts}
            dragActiveClassName={css`
               background: #edfffc;
               border: 2px solid #88b5b1 !important;
            `}
            onChange={f => setFiles(f)}
            onError={setError}
            multiple={multiple}
            name={name}
            maxFileSize={50000000} // 50MB
            minFileSize={0}
            // style={fileInputStyle}
            inputProps={{
               id: `reg-form-${fieldId.current}`,
               required: required ? files.length === 0 : false,
               style: { opacity: 1 }
            }}>
            {(files.length === 0 || multiple) && (
               <div style={{ border: '1px solid red' }}>Drop a file or click here to upload.</div>
            )}
         </FileInput>
         {files.map((file, i) => (
            <div key={file.id}>
               <b>{file.name}</b>
               {` - ${file.sizeReadable}`}
               <Button
                  width="78px"
                  onClick={() => {
                     setFiles(currentFiles => ([
                        ...currentFiles.slice(0, i),
                        ...currentFiles.slice(i + 1)
                     ]))
                  }}
                  size="xs"
                  name="Remove"
                  rounded
               />
            </div>
         ))}
      </>
   )
}

ConnectedFileInput.propTypes = {
   formik: PropTypes.object.isRequired,
   accepts: PropTypes.arrayOf(PropTypes.string),
   multiple: PropTypes.bool,
   name: PropTypes.string.isRequired,
   required: PropTypes.bool
}

// const withRef = WrappedComponent => React.forwardRef((props, ref) => (
//    <WrappedComponent {...props} forwardedRef={ref} />
// ))

export default connect(ConnectedFileInput)
