// =========================================================================================@@
// Last Updated Date: Mar 20, 2023
// Last Updated By: Steven Yuen
// Status Level: 2
// ===========================================================================================

import React, { useContext, useState } from 'react'
import { TabMenu, TabMenuButton, View } from 'oio-react'
import { Helmet } from 'react-helmet'
import { Link, useRouteMatch } from 'react-router-dom'
import { adminManageContentTypesUrl, initiativeUrl } from 'config/constants/urls'
import { useOrganization } from 'src/core/graphql/hooks'
import InitiativeList from 'src/sites/kits/Initiative/components/List'
import ObjectCreateControl from 'src/sites/kits/Object/components/CreateControl'
import ObjectMoreButton from 'src/sites/kits/Object/components/MoreButton'
import ObjectStatusBanner from 'src/sites/kits/Object/components/StatusBanner'
import PeopleAsideBlock from 'src/sites/kits/People/components/AsideBlock'
import { EmptyContentBlock, LayoutAside, LayoutContainer, LayoutContainerInner, Title }
   from 'src/sites/kits/UI'
import { InitiativeHierarchyContext } from 'src/sites/kits/Utils/InitiativeHierarchy'

// List Items
import DiscussionListItemRow from 'src/sites/kits/Object/components/ListItem/DiscussionRow'
import BlogListItemCard from 'src/sites/kits/Object/components/ListItem/BlogCard'
import GroupListItemCard from 'src/sites/kits/Object/components/ListItem/GroupCard'
import ListItemRow from 'src/sites/kits/Object/components/ListItem/Row'

const FILTER_BY_RECENT = 'recent'
const FILTER_BY_POPULARITY = 'popularity'
const FILTER_BY_FOLLOWING = 'following'

// Constants for each initiative Class
// Presumable these constants will eventually be saved to the database
// PR #951
const initiativeClassConstants = {
   discussion: {
      gridCellMin: '1fr',
      gridCellMax: '1fr',
      gridSpacing: '0px',
      listItem: DiscussionListItemRow,
      listItemIconName: 'discussion',
      showGridMargin: false,
      showInitiativeListSortControl: true,
      showTitlebarBorder: false
   },
   group: {
      gridCellMin: '240px',
      gridCellMax: '1fr',
      gridSpacing: '0px',
      listItem: GroupListItemCard,
      listItemIconName: 'discussion',
      showGridMargin: false,
      showInitiativeListSortControl: false,
      showTitlebarBorder: false
   },
   post: {
      gridCellMin: '1fr',
      gridCellMax: '1fr',
      gridSpacing: '0px',
      listItem: BlogListItemCard,
      listItemIconName: 'post',
      showGridMargin: false,
      showInitiativeListSortControl: false,
      showTitlebarBorder: false
   },
   resource: {
      gridCellMin: '1fr',
      gridCellMax: '1fr',
      gridSpacing: '0px',
      listItem: ListItemRow,
      listItemIconName: 'resource',
      showGridMargin: false,
      showInitiativeListSortControl: false,
      showTitlebarBorder: true
   }
}

const CollectionHome = () => {
   const match = useRouteMatch()
   const [initiativeListFilterMode, setInitiativeListFilterMode] = useState(FILTER_BY_RECENT)

   const { initiative: page } = useContext(InitiativeHierarchyContext)
   const { organization } = useOrganization()
   const dynamicPageType = organization.initiativeTypes.find(t => t.id === page.dynamicPageType.id)
   const initiativeTypeUIConstants = initiativeClassConstants[dynamicPageType.class] ||
      initiativeClassConstants.group

   const ListItemComponent = initiativeTypeUIConstants.listItem

   const initiativeListFilter = {
      archived: 'exclude',
      drafts: 'include',
      sortBy: 'dateLastUpdated',
      parentInitiativeId: page.id,
      limit: 20
   }

   if (initiativeListFilterMode === FILTER_BY_POPULARITY) {
      initiativeListFilter.sortBy = 'popularity'
   } else if (initiativeListFilterMode === FILTER_BY_FOLLOWING) {
      initiativeListFilter.userRelationshipFilter = {
         userId: 'me',
         hasElementType: 'subscribed'
      }
   }

   return (
      <LayoutContainer>
         <LayoutContainerInner>
            <ObjectStatusBanner />
            <Helmet title={`${page.name} | ${organization.name}`} />
            <LayoutAside.Container>
               <LayoutAside.Content>
                  <View
                     display="flex"
                     justifyContent="space-between"
                     alignItems="center"
                     height="88px"
                     className="ui-titlebar"
                     borderBottom="1px solid var(--primaryLineColor)"
                     width="100%">
                     <Title size="md">
                        {page.name}
                     </Title>
                     <View display="flex" alignItems="center">
                        <ObjectCreateControl
                           buttonHeight="var(--baseComponentHeight-sm)"
                           initiativeTypes={[dynamicPageType]}
                           parentInitiativeId={page.id}
                        />
                        {organization.currentUserCanEdit && (
                           <View display="block" width="8px" height="24px" />
                        )}
                        <ObjectMoreButton
                           buttonSize="var(--baseComponentHeight-sm)"
                           color="transparent"
                           editUrl={`${match.url}/-/edit`}
                           popoverAnchorOriginVertical="top"
                           showInitiativeName
                        />
                     </View>
                  </View>
                  <View
                     paddingBottom="40px"
                     paddingTop={initiativeTypeUIConstants.showGridMargin && '32px'}>
                     {initiativeTypeUIConstants.showInitiativeListSortControl && (
                        <View display="none">
                           <TabMenu
                              buttonPaddingHorizontal="0px"
                              buttonSpacing="20px"
                              buttonSize="xl"
                              buttonTextSize="2"
                              highlightColor="#222">
                              <TabMenuButton
                                 isActive={initiativeListFilterMode === FILTER_BY_RECENT}
                                 onClick={() => setInitiativeListFilterMode(FILTER_BY_RECENT)}
                                 name="Most Recent"
                              />
                              <TabMenuButton
                                 isActive={initiativeListFilterMode === FILTER_BY_POPULARITY}
                                 onClick={() => setInitiativeListFilterMode(FILTER_BY_POPULARITY)}
                                 name="Most Popular"
                              />
                              <TabMenuButton
                                 isActive={initiativeListFilterMode === FILTER_BY_FOLLOWING}
                                 onClick={() => setInitiativeListFilterMode(FILTER_BY_FOLLOWING)}
                                 name="Following"
                              />
                           </TabMenu>
                        </View>
                     )}
                     <div
                        style={{
                           display: 'grid',
                           width: '100%',
                           gridTemplateColumns: `repeat(auto-fill, minmax(${initiativeTypeUIConstants.gridCellMin}, ${initiativeTypeUIConstants.gridCellMax}))`,
                           gridGap: initiativeTypeUIConstants.gridSpacing
                        }}>
                        {!dynamicPageType.enabled && (
                           <div className="ui-grid-cell --full-span">
                              <EmptyContentBlock
                                 height="90px"
                                 message={(
                                    <View>
                                       {`${dynamicPageType.namePlural} have been disabled by the Admins of ${organization.name}.`}
                                       <br />
                                       <Link
                                          to={adminManageContentTypesUrl}
                                          style={{ color: 'inherit' }}>
                                          <u>Click here</u>
                                       </Link>
                                       &nbsp;to re-enable them.
                                    </View>
                                 )}
                              />
                           </div>
                        )}
                        {dynamicPageType.enabled && (
                           <InitiativeList
                              emptyContent={(
                                 <div className="ui-grid-cell --full-span">
                                    <EmptyContentBlock
                                       height="90px"
                                       message="There is currently no content to show"
                                    />
                                 </div>
                              )}
                              filter={initiativeListFilter}>
                              {initiativeList => initiativeList.items.map((initiative, index) => {
                                 const numMembers = initiative.roles.find(r => r.type === 'primary')?.numActiveUsers

                                 return (
                                    <div key={initiative.id}>
                                       <ListItemComponent
                                          borderStyle={index === 0
                                             ? ''
                                             : '1px solid var(--primaryLineColor)'
                                          }
                                          coverMedia={initiative.coverMedia}
                                          coverUrl={initiative.coverMedia?.file.thumbnailUrlW480}
                                          iconName={initiativeTypeUIConstants.listItemIconName}
                                          linkTo={initiativeUrl(initiative)}
                                          meta={[initiative.dateAdded]}
                                          name={initiative.name}
                                          numThreadEntries={initiative.thread.numEntries}
                                          numViews={initiative.numViews}
                                          privacy={initiative.privacy}
                                          publishDate={initiative.publishDate}
                                          subtitle={initiative.subtitle}
                                          summary={initiative.body.summary}
                                          surveyEnabled={initiative.appsSurveyEnabled}

                                          // Discussion props
                                          addedBy={initiative.addedBy.fullName}
                                          archived={initiative.archived}
                                          authorBadges={initiative.addedBy.badges}
                                          dateLastUpdated={initiative.dateLastUpdated}
                                          discussionFormat={initiative.discussionFormat}
                                          discussionIsClosed={initiative.discussionStatus?.closed}
                                          hasUnreadEntries={initiative.thread.hasUnreadEntries}
                                          pinned={initiative.pinned}
                                          typeSlug={initiative.type.slug}

                                          // Group props
                                          dateAdded={initiative.dateAdded}
                                          members={initiative.members}
                                          numMembers={numMembers}
                                       />
                                    </div>
                                 )
                              })}
                           </InitiativeList>
                        )}
                     </div>
                  </View>
               </LayoutAside.Content>
               <LayoutAside.Aside>
                  <PeopleAsideBlock />
               </LayoutAside.Aside>
            </LayoutAside.Container>
         </LayoutContainerInner>
      </LayoutContainer>
   )
}

export default CollectionHome
